import React, { useState, useMemo, useEffect } from 'react';
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import FiltersComponent from "../../../components/Filters";
import AddIcon from '../../../assets/icons/plus-circle.svg';
import CommunicationsIcon from '../../../assets/icons/annotation-dots.svg';
import FilterIcon from '../../../assets/icons/filter-lines.svg';
import SearchIcon from '../../../assets/icons/search.svg';
import DataTable, { TableColumn } from 'react-data-table-component';
import CustomPagination from "../../../components/Pagination";
import MaterialIconMenu, { MaterialIconMenuProps } from "../../../components/MaterialIconMenu";
import { useNavigate, NavigateFunction, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { getData, postData } from '../../../API/index';
import { AxiosResponse } from "axios";
import { AlertModalType, getButtonColor } from "../../../shared/types";
import { Actuacion } from "../../../API/interfaces";
import AlertModal from "../../../components/AlertModal/index";

const addSelectedToRow = (row: Actuacion): void => {
  document.getElementById(`row-${row.id}`)?.classList.add('active');
};

const removeSelectedToRow = (row: Actuacion) => {
  document.getElementById(`row-${row.id}`)?.classList.remove('active');
};

const ActuacionesTab = (): JSX.Element => {

  const elemPerPage = 15;

  const urlGetDatos = '/api/iho/actuaciones';
  const urlGetEstados = '/api/estadosGenerales';
  const urlGetBarrios = '/api/barrios';
  const urlGetDistritos = '/api/distritos';
  const urlGetJerarquiasClasificacion = '/api/jerarquiasClasificacion';
  const urlGetClasesFuncionGIS = '/api/clasesFuncionGIS';
  const urlEnviarMINTDatos = '/api/carga/iho/actuaciones/BBDD';

  const [actuaciones, setActuaciones] = useState<Actuacion[]>(() => {
    const storedData = localStorage.getItem('actuaciones');
    return storedData ? JSON.parse(storedData) : [];
  });
  const [selectedRows, setSelectedRows] = useState<Actuacion[]>();
  const [searchText, setSearchText] = useState('');
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [totalRowsActuaciones, setTotalRowsActuaciones] = useState<number>(() => {
    const storedTotalRowsActuaciones = localStorage.getItem('totalRowsActuaciones');
    return storedTotalRowsActuaciones ? parseInt(storedTotalRowsActuaciones, 10) : 0;
  });
  const [modalConfig, setModalConfig] = useState<{ title: string; type: AlertModalType, description?: string, onSubmit?: () => void, onClose?: () => void } | null>(null);
  const navigate = useNavigate() as NavigateFunction;
  const { token } = useAuth();

  const [opcionesEstados, setOpcionesEstados] = useState<{ text: string; value: string }[]>([]);
  const [opcionesBarrios, setOpcionesBarrios] = useState<{ text: string; value: string }[]>([]);
  const [opcionesDistritos, setOpcionesDistritos] = useState<{ text: string; value: string }[]>(() => {
    const storedDistritos = localStorage.getItem('distritos');
    return storedDistritos ? JSON.parse(storedDistritos) : [];
  });

  const [distritoSeleccionado, setDistritoSeleccionado] = useState<string>('');
  const [todosLosBarrios, setTodosLosBarrios] = useState<{ text: string; value: string; distrito: string; }[]>(() => {
    const storedBarrios = localStorage.getItem('todosLosBarrios');
    return storedBarrios ? JSON.parse(storedBarrios) : [];
  });

  const [opcionesClasesFuncionGIS, setOpcionesClasesFuncionGIS] = useState<{ id: string, text: string; value: string }[]>([]);
  const [opcionesJerarquiasClasificacion, setOpcionesJerarquiasClasificacion] = useState<{ text: string; value: string; id_clase: string; }[]>([]);
  const [claseFuncionGISSeleccionada, setClaseFuncionGISSeleccionada] = useState<string>('');
  const [todasLasJerarquiasClasificacion, setTodasLasClaseFuncionGIS] = useState<{ text: string; value: string; id_clase: string; }[]>(() => {
    const storedJerarquiasClasificacion = localStorage.getItem('todasLasJerarquiasClasificacion');
    return storedJerarquiasClasificacion ? JSON.parse(storedJerarquiasClasificacion) : [];
  });

  const [currentPageActuaciones, setCurrentPageActuaciones] = useState<number>(() => {
    const storedPage = localStorage.getItem('currentPageActuaciones');
    return storedPage ? parseInt(storedPage, 10) : 1;  // Inicializa en la página guardada o en 1 si no hay ninguna
  });

  // Guardar en localStorage cuando cambian las opciones
  useEffect(() => {
    localStorage.setItem('distritos', JSON.stringify(opcionesDistritos));
  }, [opcionesDistritos]);

  useEffect(() => {
    localStorage.setItem('todosLosBarrios', JSON.stringify(todosLosBarrios));
  }, [todosLosBarrios]);

  useEffect(() => {
    localStorage.setItem('todasLasJerarquiasClasificacion', JSON.stringify(todasLasJerarquiasClasificacion));
  }, [todasLasJerarquiasClasificacion])

  const handleShowMap = (actuacion: Actuacion) => {
    let elements: Actuacion[] = [ actuacion ];
    if(selectedRows && selectedRows.length > 0) {
      elements = selectedRows;
    }
    const state = { elements, tipo: 'Actuación', from: 'ActuaciónListado' };
    window.history.pushState(state, '', ''); // Hay que hacer este push state porque sino al ir al mapa desde el listado
                                             // y dar a atrás, sale que venimos desde la home, ya que es lo último en el historial
    navigate('/mapa-fuentes', { state });
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleSentToMINT = async (row: Actuacion) => {
    let elements: string[] = [ String(row.id) ];
    if(selectedRows && selectedRows.length > 0) {
      elements = elements.concat(selectedRows.flatMap((selectedRow) => String(selectedRow.id)));
    }

    const formData = {
      ids: elements
    };

    const response = await postData(`${urlEnviarMINTDatos}`, formData, token, navigate);
    if(response.status === 200) {
      const config = {
        title: 'Elemento cargado en gestión de envíos',
        description: 'El elemento se ha puesto en cargas de envíos',
        type: 'ok' as AlertModalType,
        onSubmit: handleCloseModal,
        onClose: handleCloseModal
      };
      setModalConfig(config);
    }
  };

  const handleCloseModal = () => {
    setModalConfig(null);
  };

  // Filtrar actuaciones según el texto de búsqueda
  const filteredActuaciones = useMemo(() => {
    return actuaciones.filter(actuacion =>
      Object.values(actuacion).some(value =>
        value && value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [actuaciones, searchText]);

  // Filtrar los barrios según el distrito seleccionado
  const handleDistritoChange = (nuevoDistrito: string) => {
    setDistritoSeleccionado(nuevoDistrito);

    let barriosFiltrados = todosLosBarrios;
    if(nuevoDistrito !== '') { // Si selecciona '', mostramos todos los barrios
      barriosFiltrados = todosLosBarrios.filter(barrio => barrio.distrito == nuevoDistrito);
    }
    setOpcionesBarrios(barriosFiltrados); // Actualiza las opciones de barrios según el distrito seleccionado
  };

  // Filtrar jerarquías según la clase de función GIS seleccionada
  const handleClaseFuncionGISChange = (nuevaClase: string) => {
    const clase = Object.values(opcionesClasesFuncionGIS).find(opcion => opcion.value === nuevaClase);

    let jerarquiasClasificacionFiltradas = todasLasJerarquiasClasificacion;
    if (clase && nuevaClase !== '') {
      jerarquiasClasificacionFiltradas = todasLasJerarquiasClasificacion.filter(jerarquia => jerarquia.id_clase == clase.id);
    }

    setClaseFuncionGISSeleccionada(nuevaClase);
    setOpcionesJerarquiasClasificacion(jerarquiasClasificacionFiltradas); // Actualiza las opciones de barrios según el distrito seleccionado
  };

  const columns: TableColumn<Actuacion>[] = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row.id,
        sortable: true,
        grow: 1,
        minWidth: '70px',
        maxWidth: '70px'
      },
      {
        name: 'Id Actuación',
        selector: (row) => row.idActuacion,
        sortable: true,
        grow: 2,
      },
      {
        name: 'Distrito',
        selector: (row) => {
          const distrito = opcionesDistritos.find(d => d.value == row.distrito);
          return distrito ? `${distrito.text}` : row.distrito;
        },
        sortable: true,
        grow: 3,
      },
      {
        name: 'Barrio',
        selector: (row) => {
          const barrio = todosLosBarrios.find(b => b.value == row.barrio);
          return barrio ? `${barrio.text}` : row.barrio;
        },
        sortable: true,
        grow: 3,
      },
      {
        name: 'Clase Función GIS',
        selector: (row) => row.claseFuncionGIS,
        sortable: true,
        grow: 3,
      },
      {
        name: 'Jerarquía Clasificación',
        selector: (row) => row.jerarquiaClasificacion,
        sortable: true,
        grow: 5,
      },
      {
        name: 'Nombre vía',
        selector: (row) => row.nombreVia,
        sortable: true,
        grow: 3,
      },
      {
        name: 'Número vía',
        selector: (row) => row.numeroVia,
        sortable: true,
        grow: 2
      },
      {
        name: 'Estado General',
        selector: (row) => row.estado_general.label, // Selector que devuelve un tipo primitivo
        sortable: true,
        cell: (row) => (
          <Button className="estado-button" color={getButtonColor(row.estado_general.nombre)}>
            {row.estado_general.label}
          </Button>
        ),
        grow: 2
      },
      {
        cell: row => {
          const paramsToMenu: MaterialIconMenuProps<Actuacion> = {
            row,
            size: 'medium',
            rows: selectedRows && selectedRows.length ? selectedRows : [row],
            onOpenMenu: () => addSelectedToRow(row),
            onCloseMenu: () => removeSelectedToRow(row),
            actions: [
              {label: 'Ver en mapa', onClick: row => handleShowMap(row)},
              {
                label: 'Editar',
                onClick: row => navigate(`/fuentes-ornamentales/actuaciones/editar/${row.id}`, { state: { from: 'Actuaciones' } } ),
                onlyApplyToOne: true,
              },
              { label: `Enviar a MiNT`, onClick: row => handleSentToMINT(row) },
            ],
          };
          return <MaterialIconMenu {...paramsToMenu} />;
        },
        allowOverflow: true,
        button: true,
        width: '71px',
      },
    ],
    [selectedRows],
  );

  /* Filtros */
  type DatosFiltrosType = {
    id: string,
    idActuacion: string,
    idActuacionExterno: string,
    idInventario: string,
    contrato: string,
    lote: string,
    distrito: string,
    barrio: string,
    nombreVia: string,
    numeroVia: string,
    claseFuncionGIS: string,
    jerarquiaClasificacion: string,
    id_estado_general: string,
  };

  const [filtros, setFiltros] = useState<DatosFiltrosType>({
    id: '',
    idActuacion: '',
    idActuacionExterno: '',
    idInventario: '',
    contrato: '',
    lote: '',
    distrito: '',
    barrio: '',
    nombreVia: '',
    numeroVia: '',
    claseFuncionGIS: '',
    jerarquiaClasificacion: '',
    id_estado_general: ''
  });

  const camposFiltros = [
    { name: 'id', label: 'ID', placeholder: 'ID', typeElem: 'input', type: 'text' },
    { name: 'idActuacion', label: 'idActuacion', placeholder: 'idActuacion', typeElem: 'input', type: 'text' },
    { name: 'idActuacionExterno', label: 'idActuacionExterno', placeholder: 'idActuacionExterno', typeElem: 'input', type: 'text' },
    { name: 'idInventario', label: 'idInventario', placeholder: 'idInventario', typeElem: 'input', type: 'text' },
    { name: 'contrato', label: 'contrato', placeholder: 'contrato', typeElem: 'input', type: 'text' },
    { name: 'lote', label: 'lote', placeholder: 'lote', typeElem: 'input', type: 'text' },
    { name: 'distrito', label: 'Distrito', placeholder: 'Distrito', typeElem: 'select', type: 'select', options: opcionesDistritos, onChange: handleDistritoChange },
    { name: 'barrio', label: 'Barrio', placeholder: 'Barrio', typeElem: 'select', type: 'select', options: opcionesBarrios },
    { name: 'nombreVia', label: 'Nombre de vía', placeholder: 'Nombre de vía', typeElem: 'input', type: 'text' },
    { name: 'numeroVia', label: 'Número de vía', placeholder: 'Número de vía', typeElem: 'input', type: 'text' },
    { name: 'id_estado_general', label: 'Estado', placeholder: 'Estado', typeElem: 'select', type: 'select', options: opcionesEstados },
    { name: 'claseFuncionGIS', label: 'Clase Función GIS', placeholder: 'Clase Función GIS', typeElem: 'select', type: 'select', options: opcionesClasesFuncionGIS,
      onChange: (value: string) => {
        handleClaseFuncionGISChange(value);
      }
    },
    { name: 'jerarquiaClasificacion', label: 'Jerarquía Clasificación', placeholder: 'Jerarquía Clasificación', typeElem: 'select', type: 'select', options: opcionesJerarquiasClasificacion },
  ];

  const handleAplicarFiltros = (filtros: DatosFiltrosType) => {
    console.log('Filtros aplicados:', filtros);
    setFiltros(filtros);
    setCurrentPageActuaciones(0);
    fetchData(0, filtros);
    setMostrarFiltros(false);
  };

  const handleMostrarFiltros = () => {
    setMostrarFiltros(!mostrarFiltros);
  };
  /* END Filtros*/

  // Llamada a la API para recibir los datos
  const fetchData = async (page: number, filters?: DatosFiltrosType, llamadaAutomatica?: boolean) => {
    try {
      if(llamadaAutomatica === true && page !== currentPageActuaciones) {
        return;
      }
      
      const offset = page * elemPerPage;
      let url = `${urlGetDatos}?limit=${elemPerPage}&offset=${offset}`;

      if (filters) {
        const filtrosQueryString = Object.entries(filters)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');

        // Agrupa los filtros en un campo 'filtros'
        url += `&filtros=${encodeURIComponent(filtrosQueryString)}`;
      }

      const response: AxiosResponse = await getData(url, navigate, token);
      if (response && response.status === 200) {
        const newData = response.data.actuaciones;
        setActuaciones(newData);

        // Guardamos las actuaciones en localStorage
        localStorage.setItem('actuaciones', JSON.stringify(newData));

        // Guardamos el total elementos
        setTotalRowsActuaciones(response.data.total);
        localStorage.setItem('totalRowsActuaciones', response.data.total);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  const getEstados = async () => {
    try {
      let url = `${urlGetEstados}`;

      const response: AxiosResponse = await getData(url, navigate, token);
      if (response && response.status === 200) {
        const newData = response.data.estados;

        // Mapeamos los datos a un formato adecuado para el select (text y value)
        const opciones = newData.map((estado: any) => ({
          text: estado.label,
          value: estado.id
        }));

        // Actualiza el estado con las nuevas opciones
        setOpcionesEstados(opciones);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  const getBarrios = async () => {
    try {
      const response: AxiosResponse = await getData(urlGetBarrios, navigate, token);
      if (response && response.status === 200) {
        const newData = response.data.barrios;

        // Almacena todos los barrios
        const opciones = newData.map((barrio: any) => ({
          text: barrio.codigo + ' - ' +barrio.label,
          value: barrio.codigo,
          distrito: barrio.id_distrito,
        }));

        setTodosLosBarrios(opciones);
        setOpcionesBarrios(opciones); // Establece inicialmente todos los barrios
        localStorage.setItem('todosLosBarrios', JSON.stringify(opciones)); // Guardar en localStorage
      }
    } catch (error) {
      console.error('Error al obtener datos de barrios', error);
    }
  };

  const getDistritos = async () => {
    try {
      if (!opcionesDistritos.length) {
        const response: AxiosResponse = await getData(urlGetDistritos, navigate, token);
        if (response && response.status === 200) {
          const newData = response.data.distritos;

          const opciones = newData.map((distrito: any) => ({
            text: distrito.codigo + ' - ' + distrito.label,
            value: distrito.codigo,
          }));

          setOpcionesDistritos(opciones);
          localStorage.setItem('distritos', JSON.stringify(opciones)); // Guardar en localStorage
        }
      }
    } catch (error) {
      console.error('Error al obtener datos de distritos', error);
    }
  };

  const getClasesFuncionGIS = async () => {
    try {
      const response: AxiosResponse = await getData(urlGetClasesFuncionGIS, navigate, token);
      if (response && response.status === 200) {
        const newData = response.data.clasesFuncionGIS;

        const opciones = newData.map((clase: any) => ({
          id: clase.id,
          text: clase.label,
          value: clase.label,
        }));

        setOpcionesClasesFuncionGIS(opciones);
      }
    } catch (error) {
      console.error('Error al obtener datos de ClasesFuncionGIS', error);
    }
  };

  const getJerarquiasClasificacion = async () => {
    try {
      const response: AxiosResponse = await getData(urlGetJerarquiasClasificacion, navigate, token);
      if (response && response.status === 200) {
        const newData = response.data.jerarquiasClasificacion;

        const opciones = newData.map((jerarquia: any) => ({
          text: jerarquia.label,
          value: jerarquia.label,
          id_clase: jerarquia.id_clase_funcion_gis
        }));

        setOpcionesJerarquiasClasificacion(opciones);
        localStorage.setItem('todasLasJerarquiasClasificacion', JSON.stringify(opciones)); // Guardar en localStorage
      }
    } catch (error) {
      console.error('Error al obtener datos de JerarquiasClasificacion', error);
    }
  };

  useEffect(() => {
    getEstados();
    getBarrios();
    getDistritos();
    getJerarquiasClasificacion();
    getClasesFuncionGIS();
    // fetchData(0); // Cargar los elementos al iniciar la página
  }, []);

  const handlePageChange = (page: number) => {
    // Verifica si la página es diferente de la actual
    if (page !== currentPageActuaciones) {
      setCurrentPageActuaciones(page);
      localStorage.setItem('currentPageActuaciones', page.toString());
      console.log(`Página actual almacenada: ${page}`);
      fetchData(page - 1, filtros); // Recuerda que estás usando paginación 0-indexada
    }
  };

  const handleReset = () => {
    handleDistritoChange('');
    handleClaseFuncionGISChange('');
  };

  return (
    <div className="actuaciones-tab">

      <div className="header-container">
        <h3>Listado</h3>

        <div>
          <Button color="white" onClick={() => navigate(`/fuentes-ornamentales/actuaciones/crear`, { state: { from: 'Actuaciones' } } )}>
            <img src={AddIcon} alt="Añadir" />
            Añadir
          </Button>

          <Button color="white" onClick={() => navigate(`/comunicaciones`, { state: { from: 'Actuaciones' } } )}>
            <img src={CommunicationsIcon} alt="Comunicaciones" />
            Comunicaciones
          </Button>

          <Button color="white" onClick={handleMostrarFiltros}>
            <img src={FilterIcon} alt="Filtrar por" />
            Filtrar por
          </Button>

          <Input placeholder="Buscar" iconSrc={SearchIcon} onChange={handleSearch} />

        </div>
      </div>

      <div className="content-container">

        {(!actuaciones || actuaciones.length == 0) && !Object.values(filtros).some(val => val !== '') && (
        <div className="no-actuaciones">
          <p>Para visualizar información primero debe filtrar</p>
          <Button color="primary" onClick={handleMostrarFiltros}>Abrir panel de filtros</Button>
        </div>
        )}

        {(filteredActuaciones && filteredActuaciones.length > 0) && (
          <DataTable
            columns={columns}
            data={filteredActuaciones}
            pagination
            paginationServer
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            responsive
            paginationPerPage={elemPerPage}
            paginationTotalRows={totalRowsActuaciones}
            paginationComponent={CustomPagination}
            paginationDefaultPage={currentPageActuaciones}
            onChangePage={handlePageChange}
            onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
          />
        )}

        {(actuaciones && ((searchText !== '') || Object.values(filtros).some(val => val !== '')) && (!filteredActuaciones || filteredActuaciones.length == 0)) && (
          <div className="no-data">No hay datos con los filtros aplicados</div>
        )}
        
        {mostrarFiltros &&
          <FiltersComponent
            datos={filtros}
            camposFormulario={camposFiltros}
            onHide={handleMostrarFiltros}
            onAplicar={handleAplicarFiltros}
            onReset={handleReset}
          />
        }

        {modalConfig && (
          <AlertModal
            title={modalConfig.title}
            description={modalConfig.description}
            type={modalConfig.type}
            onSubmit={modalConfig.onSubmit ? modalConfig.onSubmit : handleCloseModal}
            onClose={modalConfig.onClose ? modalConfig.onClose : handleCloseModal}
          />
        )}
      </div>

    </div>
  )
};

export default ActuacionesTab;