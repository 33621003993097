import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import CreatAndEditGeneralPage, { CampoElemento, DatosType } from "../../../CrearEditarGeneral";
import { AxiosResponse } from 'axios';
import { getData, postData, putData, deleteData } from '../../../../API/index';
import { useAuth } from '../../../../context/AuthContext';

function CrearEditarActuacionesPage() {

  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from?: string } | undefined)?.from || 'atrás';
  const { id } = useParams();
  const [datos, setDatos] = useState<DatosType>({});
  const urlDatos = '/api/iho/actuaciones';
  const urlGetEstados = '/api/estadosGenerales';
  const { token } = useAuth();
  const [opcionesEstados, setOpcionesEstados] = useState<{ text: string; value: string }[]>([]);

  const camposInput: CampoElemento[] = [
    { id: 'id', label: 'ID y idActuacion', flex: '4' , elementos: [
      { name: 'id', placeholder: 'ID', flex: '2', typeElem: 'input', type: 'text', readOnly: true },
      { name: 'idActuacion', placeholder: 'idActuacion', flex: '2', typeElem: 'input', type: 'text', readOnly: true },
    ]},
    { id: 'idActuacionExternoYIdInventario', label: 'idActuacionExterno', flex: '2', elementos: [
      { name: 'idActuacionExterno', placeholder: 'idActuacionExterno', flex: '1', typeElem: 'input', type: 'text' },
      { name: 'idInventario', placeholder: 'idInventario', flex: '1', typeElem: 'input', type: 'text' },
    ]},
    { id: 'contratoYLote', label: 'Contrato y Lote', flex: '2', elementos: [
      { name: 'contrato', placeholder: 'Contrato', flex: '1', typeElem: 'input', type: 'text' },
      { name: 'lote', placeholder: 'Lote', flex: '1', typeElem: 'input', type: 'text' },
    ]},
    { id: 'barrio', label: 'Barrio y Distrito', flex: '2', elementos: [
      { name: 'barrio', placeholder: 'Barrio', flex: '1', typeElem: 'input', type: 'text' },
      { name: 'distrito', placeholder: 'Distrito', flex: '1', typeElem: 'input', type: 'text' },
    ]},
    { id: 'nombreYNroVia', label: 'Nombre y Número de vía', flex: '2', elementos: [
      { name: 'nombreVia', placeholder: 'Nombre de vía', flex: '1', typeElem: 'input', type: 'text' },
      { name: 'numeroVia', placeholder: 'Número de vía', flex: '1', typeElem: 'input', type: 'text' },
    ]},
    { id: 'claseFuncionGIS', label: 'Clase Función GIS', flex: '4' , elementos: [
      { name: 'claseFuncionGIS', placeholder: 'Clase Función GIS', flex: '4', typeElem: 'input', type: 'text' },
    ]},
    { id: 'jerarquiaClasificacion', label: 'Jerarquía Clasificación', flex: '4' , elementos: [
      { name: 'jerarquiaClasificacion', placeholder: 'Jerarquía Clasificación', flex: '4', typeElem: 'input', type: 'text' },
    ]},
    { id: 'coordenadas', label: 'Coordenadas', flex: '4' , elementos: [
      { name: 'coordenadas', placeholder: 'Coordenadas', flex: '4', typeElem: 'input', type: 'text' },
    ]},
    { id: 'estado', label: 'Estado', flex: '4' , elementos: [
      { name: 'id_estado_general', placeholder: 'Estado', flex: '4', typeElem: 'select', type: 'select', options: opcionesEstados },
    ]}
  ];

  const camposJSON: CampoElemento[] = [
    { id: 'json', label: 'JSON', flex: '4' , elementos: [
      { name: 'json', placeholder: 'JSON', flex: '4', typeElem: 'textarea', type: 'text' },
    ]}
  ];

  const camposFormulario: CampoElemento[] = [
    { id: '1',
      label: '',
      tabs: [
        { id: 'campos', label: 'Editar campos', campos: camposInput },
        { id: 'json', label: 'Editar JSON', campos: camposJSON }
      ]
    }
  ];

  const getEstados = async () => {
    try {
      let url = `${urlGetEstados}`;

      const response: AxiosResponse = await getData(url, navigate, token);
      if (response && response.status === 200) {
        const newData = response.data.estados;

        // Mapeamos los datos a un formato adecuado para el select (text y value)
        const opciones = newData.map((estado: any) => ({
          text: estado.label,
          value: estado.id
        }));

        // Actualiza el estado con las nuevas opciones
        setOpcionesEstados(opciones);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  useEffect(() => {
    if (id) {
      // Cogemos el listado de estados
      getEstados();

      // Llamamos a la api para coger la actuación a editar
      fetchData(Number(id));
    }
  }, [id]);

  // Llamada a la API para recibir los datos
  const fetchData = async (id: number) => {
    try {
      const response: AxiosResponse = await getData(`${urlDatos}/${id}`, navigate, token);
      if (response && response.status === 200) {
        // Establecer los datos en el estado
        setDatos(response.data);
      }

    } catch (error) {
      console.error('Error al obtener datos desde la API', error);
    }
  };

  const handleSaveElem = async (formData?: DatosType): Promise<AxiosResponse<any, any> | null> => {    // Lógica para guardar los datos
    let response: AxiosResponse;

    if(formData) {
      if (id) { // Si hay un ID, estás editando
        response = await putData(`${urlDatos}/${id}`, formData, navigate, token);
      } else { // Si no hay un ID, estás creando
        response = await postData(`${urlDatos}`, formData, token, navigate);
      }
      return response;
    } else {
      return Promise.resolve(null as unknown as AxiosResponse<any, any>); // Devuelve null
    }
  };

  const handleDeleteElem = async(): Promise<AxiosResponse<any, any>> => {
    // Lógica para borrar los datos
    if (id) {
      return await deleteData(`${urlDatos}/${id}`, navigate, token);
    }
    return Promise.resolve(null as unknown as AxiosResponse<any, any>); // Devuelve null
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <CreatAndEditGeneralPage
      title={id ? 'Editar Actuación' : 'Nueva Actuación'}
      elementsType='actuación'
      datos={datos}
      camposFormulario={camposFormulario}
      onSave={handleSaveElem}
      onCancel={handleCancel}
      onDelete={handleDeleteElem}
    />

  );
}

export default CrearEditarActuacionesPage;