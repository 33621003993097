import React, { useState, useContext, useEffect } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';

import logo from '../../assets/logos/iris-black.svg';
import '../../assets/scss/pages/_login.scss';
import { Link } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ErrorContext, ErrorContextType } from '../../context/ErrorContext';
import axios, { AxiosError } from 'axios';

function LoginPage() {
  const navigate = useNavigate();
  const { login, logout } = useAuth();
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    remember: false,
  });


  const handleInputChange = (field: string, value: string) => {
    setLoginData({ ...loginData, [field]: value });
  };

  const { showErrorModal } = useContext<ErrorContextType>(ErrorContext);

  const handleLogin = async () => {
    try {
      await login(loginData, navigate);

      // Eliminamos los listados de inventario y actuaciones que tengamos almacenados:
      localStorage.setItem('inventario', '');
      localStorage.setItem('actuaciones', '');
      localStorage.setItem('totalRows', '');

      navigate('/home');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        showErrorModal(
          'Error en login',
          'Hubo un error durante el inicio de sesión: ' + error?.response?.data?.message,
          'error');
        console.error('Error de inicio de sesión:', error);
      }
    }
  };

  // Detectar la tecla "Enter" y hacer login
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="login-page" onKeyDown={handleKeyDown} tabIndex={0}>
      <div>
        <div>
          <img src={logo} alt="Home"/>
          <h1>Ingresar a mi cuenta</h1>
          <Input label="Email"
                 value={loginData.email}
                 placeholder="Introducir email"
                 onChange={(value) => handleInputChange('email', value)}
          />
          <Input label="Contraseña"
                 value={loginData.password}
                 placeholder="••••••••"
                 type="password"
                 onChange={(value) => handleInputChange('password', value)}
          />

          <div className="links-container">
            <Checkbox
              checked={loginData.remember}
              onChecked={ checked => {
                setLoginData({ ...loginData, remember: checked });
              }}
              label="Recordarme"
            />

            {/*
            <Link className="link" to="/recuperar-contrasena">
              Olvidé mi contraseña
            </Link>
             */}
          </div>

          <Button color="primary" onClick={handleLogin}>Ingresar</Button>
        </div>
      </div>

      <div className="img"></div>
    </div>
  );
}

export default LoginPage;